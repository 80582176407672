import './index.css';
import { useTheme } from 'components/ThemeProvider';
import lightLogo from '../../assets/lightLogo.png';
import darkLogo from '../../assets/darkLogo.png';

function Monogram({ highlight, className, ...props }) {
  const { themeId } = useTheme();
  const isDark = themeId === 'dark';

  return <img alt="icon" src={isDark ? darkLogo : lightLogo} />;
}

export default Monogram;
