import React, { useEffect, useRef } from 'react';

import { Fragment } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import { reflow } from 'utils/transition';
import DecoderText from 'components/DecoderText';
import NotfoundPoster from 'assets/notfound.jpg';
import Heading from 'components/Heading';
import Text from 'components/Text';
import { Button } from 'components/Button';
import './index.css';
import Notfound from 'assets/whyredbullsucks.mp4';

const RedBull = () => {
  const vidRef = useRef();

  useEffect(() => {
    const video = vidRef.current;
    const handlePlay = () => {
      video.muted = false;
    };
    video.addEventListener('play', handlePlay);
    return () => {
      video.removeEventListener('play', handlePlay);
    };
  }, []);

  return (
    <section className="page-404">
      <Helmet>
        <title tag="title">whyredbullsucks</title>
        <meta name="description" content="why red bull sucks" />
      </Helmet>
      <Transition appear in={true} timeout={0} onEnter={reflow}>
        {status => (
          <Fragment>
            <div className="page-404__details">
              <div className="page-404__text">
                <Heading
                  className={classNames('page-404__title', `page-404__title--${status}`)}
                  level={0}
                >
                  happy bday jesse!
                </Heading>
              </div>
            </div>

            <div
              className={classNames(
                'page-404__video-container',
                `page-404__video-container--${status}`
              )}
            >
              <video
                autoPlay
                loop
                playsInline
                controls
                muted
                ref={vidRef}
                className={classNames('page-404__video ', `page-404__video--${status}`)}
              >
                <source src={Notfound} type="video/mp4" />
              </video>
            </div>
          </Fragment>
        )}
      </Transition>
    </section>
  );
};

export default RedBull;
